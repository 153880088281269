.wrapper {
	border: 1px dashed #8b95b9;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	width: 100%;
}

.file_input {
	display: flex;
	justify-content: center;
	border: none;
	padding: 0.6rem 1.2rem;
	color: #cbcbcb;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	&__is_valid {
		cursor: pointer;
	}

	&__hover {
		border: 1px solid #172b73;
	}

	&__file_remove {
		margin-left: 10px;
		border-radius: 50%;
		display: inline-flex;
		height: 14px;
		width: 14px;
		align-items: center;
		justify-content: center;
		background: #ccc;

		&:hover {
			background: #dc3545;
			color: #fff;
		}
	}

	&__placeholder {
		color: #838692;
		font-weight: 600;
		
		&_highlight {
			font-weight: 600;
			color: #0F4697;
		}

		&_file_name {
			color: #000;
		}

		&_file_error_text {
			display: inline-block;
			padding: 0.25em 0.4em;
			font-size: 75%;
			font-weight: 700;
			line-height: 1;
			text-align: center;
			white-space: nowrap;
			vertical-align: baseline;
			border-radius: 0.25rem;
			transition:
				color 0.15s ease-in-out,
				background-color 0.15s ease-in-out,
				border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;
			color: #fff;
			background-color: #dc3545;
		}
	}

	&__disabled {
		background: #e9ecef;
		cursor: not-allowed;
	}
}
